const apps = [
  
  {
    path: '/timeline/users/:id',
    name: 'user-timeline',
    component: () => import('@/views/apps/timeline/TimelineDetail.vue'),
    props: route => ({ params: route.params }),
    meta: {
      title: 'Timeline User',
      layout: 'content',
      resource: 'timeline',
      action: 'read'
    },
  },
  {
    path: '/timeline/teams/:id',
    name: 'team-timeline',
    component: () => import('@/views/apps/timeline/TimelineDetail.vue'),
    props: route => ({params: route.params }),
    meta: {
      title: 'Timeline Team',
      layout: 'content',
      resource: 'timeline',
      action: 'read'
    },
  },
  {
    path: '/timeline/project/:id',
    name: 'project-timeline',
    component: () => import('@/views/apps/timeline/TimelineDetail.vue'),
    props: route => ({params: route.params }),
    meta: {
      title: 'Timeline Project',
      layout: 'content',
      resource: 'timeline',
      action: 'read'
    },
  },
  {
    path: '/timeline/fields/',
    name: 'field-timeline',
    component: () => import('@/views/apps/timeline/TimelineDetail.vue'),
    meta: {
      title: 'Timeline Field',
      layout: 'content',
      resource: 'timeline',
      action: 'read'
    },
  },
  {
    path: '/timeline/office/:id',
    name: 'office-timeline',
    component: () => import('@/views/apps/timeline/TimelineDetail.vue'),
    props: route => ({params: route.params }),
    meta: {
      title: 'Timeline Office',
      layout: 'content',
      resource: 'timeline',
      action: 'read'
    },
  },
 
  //
  //* ——— Accounting ——————————————————
  //

  {
    path: '/suppliers',
    name: 'suppliers-list', 
    props: (route) => ({
      ...route.params
    }),
    component: () => import('@/views/apps/supplier/supplier-list/SupplierList.vue'),
    meta: {
      title: 'Suppliers',
      layout: 'content',
      resource: 'supplier',
      action: 'manage'
    },
  },


  //
  //* ——— PO ——————————————————
  //

  {
    path: '/po',
    name: 'po',
    component: () => import('@/views/apps/purchaseorder/po-list/POList.vue'),
    meta: {
      title: 'Purchase Order',
      layout: 'content',
      resource: 'po',
      action: 'update'
    },
  },
  {
    path: '/po/add/',
    name: 'po-add',
    component: () => import('@/views/apps/purchaseorder/po-add/POAdd.vue'),
    props: route => ({...route.params }),
    meta: {
      title: 'Purchase Order',
      layout: 'content',
      resource: 'po',
      action: 'manage'
    },
  },
  {
    path: '/po/:poId',
    name: 'po-view',
    component: () => import('@/views/apps/purchaseorder/po-view/POView.vue'),
    props: route => ({...route.params }),
    meta: {
      title: 'Purchase Order',
      layout: 'content',
      resource: 'po',
      action: 'update'
    },
  },
  // {
  //   path: '/po/edit/:id',
  //   name: 'po-edit',
  //   component: () => import('@/views/apps/purchaseorder/po/PO.vue'),
  //   meta: {
  //     title: 'Purchase Order',
  //     layout: 'content',
  //     resource: 'sales',
  //     action: 'add'
  //   },
  // },

  //
  //* ——— User ——————————————————
  //

  {
    path: '/users',
    name: 'user-list', 
    props: (route) => ({
      ...route.params
    }),
    component: () => import('@/views/apps/user/user-list/UserList.vue'),
    meta: {
      title: 'Users',
      layout: 'content',
      resource: 'user',
      action: 'read'
    },
  },
  {
    path: '/users/:email',
    name: 'user-view', 
    props: (route) => ({
      ...route.params
    }),
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      title: 'Users',
      layout: 'content',
      resource: 'user',
      action: 'read'
    },
  },
  {
    path: '/profile/',
    name: 'user-profile',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      title: 'User Profile',
      layout: 'content',
      resource: 'user',
      action: 'read'
    },
  },

 

  //
  //* ——— Hubspot ——————————————————
  //

  {
    path: '/hubspot',
    name: 'hubspot',
    component: () => import('@/views/apps/hubspot/Hubspot.vue'),
    props: true,
    meta: {
      title: 'Hubspot',
      layout: 'content',
      resource: 'sales',
      action: 'read'
    },
  },

  //
  //*___Academy__

//

{
  path: '/academy/dashboard',
  name: 'academy-dashboard',
  component: () => import('@/views/apps/academy/dashboard/AcademyDashboard.vue'),
  meta: {
    title: 'Academy',
    layout: 'content',
    resource: 'academy',
    action: 'read'
  },
},

{
  path: '/academy/create',
  name: 'academy-course-create',
  component: () => import('@/views/apps/academy/course-create/CourseCreate.vue'),
  meta: {
    title: 'Course Creation',
    layout: 'content',
    resource: 'academy',
    action: 'add'
  },
},

{
  path: '/academy/courses',
  name: 'academy-course-list',
  component: () => import('@/views/apps/academy/course-list/CourseList.vue'),
  meta: {
    title: 'Courses',
    layout: 'content',
    resource: 'academy',
    action: 'read'
  },
},

{
  path: '/academy/courses/:slug',
  name: 'academy-course-detail',
  props: (route) => ({
    ...route.params
  }),
  component: () => import('@/views/apps/academy/course-view/CourseView.vue'),
  meta: {
    title: 'Course Detail',
    layout: 'content',
    resource: 'academy',
    action: 'read'
  },
},

  //
  //* ——— Project ——————————————————
  //

  {
    path: '/projects',
    name: 'project-list',
    component: () => import('@/views/apps/project/project-list/ProjectList.vue'),
    props: true,
    meta: {
      title: 'Projects',
      layout: 'content',
      resource: 'public'
    },
  },
  {
      path: '/projects/:id',
      component: () => import('@/views/apps/project/project-view/ProjectView.vue'),
      children: [
        {
          path: 'overview',
          alias: '',
          name: 'project-view',
          props: (route) => ({
            ...route.params
          }),
          meta: {
            title: 'Project Overview',
            layout: 'content',
            resource: 'project',
            action: 'add'
          },
        },
        {
          path: 'tasks',
          name: 'project-view-tasks',
          props: (route) => ({
            ...route.params
          }),
          meta: {
            title: 'Project Tasks',
            layout: 'content',
            resource: 'project',
            action: 'add'
          },
          children: [
           
            {
              path: ':taskId',
              name: 'project-view-tasks-id',
              props: (route) => ({
                ...route.params
              }),
              meta: {
                title: 'Project Task',
                layout: 'content',
                resource: 'project',
                action: 'add'
              },
              
            },
          ]
        },
        {
          path: 'swof',
          name: 'project-view-swof',
          props: (route) => ({
            ...route.params
          }),
          meta: {
            title: 'Project SWOF',
            layout: 'content',
            resource: 'beta',
            action: 'read'
          },
          
        },
        {
          path: 'feedback',
          name: 'project-view-feedback',
          props: (route) => ({
            ...route.params
          }),
          meta: {
            title: 'Project CSAT',
            layout: 'content',
            resource: 'project',
            action: 'add'
          },
        },
        {
          path: 'norms',
          name: 'project-view-norms',
          props: (route) => ({
            ...route.params
          }),
          meta: {
            title: 'Project Norms',
            layout: 'content',
            resource: 'project',
            action: 'add'
          },
        },
        {
          path: 'po',
          name: 'project-view-po',
          props: (route) => ({
            ...route.params
          }),
          meta: {
            title: 'Project POs',
            layout: 'content',
            resource: 'project',
            action: 'add'
          }
        },
      ]
  },
  {
    path: '/projects/:id/po/add',
    name: 'project-view-po-add',
    component: () => import('@/views/apps/purchaseorder/po-add/POAdd.vue'),
    props: route => ({...route.params }),
    meta: {
      title: 'Project PO',
      layout: 'content',
      resource: 'project',
      action: 'add'
    },
  },
  {
    path: '/projects/:id/po/:poId',
    name: 'project-view-po-id',
    component: () => import('@/views/apps/purchaseorder/po-view/POView.vue'),
    props: route => ({...route.params }),
    meta: {
      title: 'Project PO',
      layout: 'content',
      resource: 'project',
      action: 'add'
    },
  },

  //
  //* ——— Survey ——————————————————
  //

  {
    path: '/surveys/:id/:country',
    name: 'survey-project-view',
    component: () => import('@/views/apps/survey/wizard/Builder.vue'),
    props: route => ({...route.params }),
    meta: {
      title: "Survey",
      layout: 'content',
      resource: 'survey',
      action: 'update'
    },
  },

  {
      path: '/surveys',
      name: 'survey-view',
      component: () => import('@/views/apps/survey/SurveyView.vue'),
      meta: {
        title: 'Survey',
        layout: 'content',
        resource: 'survey',
        action: 'update'
      },
  },

   //
  //* ——— ChatGPT ——————————————————
  //

  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    props: true,
    meta: {
      title: 'Chat',
      layout: 'content',
      resource: 'chat',
      action: 'read'
    },
  },



  //
  //* ——— Viz Var Check ——————————————————
  //

  {
    path: '/forsta',
    name: 'forsta',
    component: () => import('@/views/apps/forsta/Forsta.vue'),
    props: true,
    meta: {
      title: 'Forsta',
      layout: 'content',
      resource: 'forsta',
      action: 'update'
    },
  },

]


export default apps
